.user-count-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
}



.count-number {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(236, 234, 234);
    display: inline-block;
}

.count-label {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
}