.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #dfe2e8, #486d98);
  font-family: 'Poppins', sans-serif;
  color: #fff;
}

.App-header {
  display: grid;
  place-items: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 100px;
  /* Menambah sedikit padding */
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  text-align: center;
  width: 100%;
  max-width: 1200px;
  /* Memperbesar lebar maksimal container */
  transition: all 0.3s ease;
}

.App-header:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.8);
}

.App-logo {
  height: 100px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.App-logo:hover {
  transform: rotate(360deg);
}

h1 {
  font-size: 3rem;
  background: linear-gradient(90deg, #028fb7, #013f8b);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

h1:hover {
  letter-spacing: 3px;
}

.UserCount {
  font-size: 2rem;
  background: linear-gradient(90deg, #0072ff, #00c6ff);
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  box-shadow: 0 5px 15px rgba(0, 114, 255, 0.4);
  transition: all 0.3s ease;
}

.UserCount:hover {
  box-shadow: 0 8px 25px rgba(0, 114, 255, 0.6);
  transform: translateY(-5px);
}

@media (max-width: 768px) {
    .App-header {
        padding: 60px;
        /* Tetap responsif di perangkat kecil */
        max-width: 100%;
      }

  .App-logo {
    height: 80px;
  }

  h1 {
    font-size: 2.2rem;
  }

  .UserCount {
    font-size: 1.5rem;
  }
}